.contact-us {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $contact-us;
}

.contact-us::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.contact-us > * {
    position: relative;
    z-index: 2;
    color: white;
}

.our-commitment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: lightgray;
}

.our-commitment::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(125px);
    z-index: 1;
}

.our-commitment > * {
    position: relative;
    z-index: 2;
    color: white;
}

.about-us {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $about-us-image;
}

.about-us::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(200px);
    z-index: 1;
}

.about-us > * {
    position: relative;
    z-index: 2;
    color: white;
}

.static-slider-head2 {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    background-size: cover;
    background-position: center center;
    background-image: $banner-image;
}

.about-us-commitment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: lightblue;
}

.about-us-commitment::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(125px);
    z-index: 1;
}

.about-us-commitment > * {
    position: relative;
    z-index: 2;
    color: white;
}