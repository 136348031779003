
$bodyfont:'Montserrat',
sans-serif;
$headingfont:'Montserrat',
sans-serif;

/*Theme Colors*/
$bodycolor: #f7f9fc;  // A soft off-white for a modern feel
$headingtext: #2c3e50; // Darker shade for headings
$bodytext: #7f8c8d;    // Softer gray for body text
$themecolor: #3498db;  // A vibrant blue for the main theme color
$themecolor-alt: #1abc9c; // A refreshing teal
$themecolor-dark: #2980b9; // Darker shade of the theme color

/*Bootstrap Color*/
$danger: #e74c3c;     // Bright red for alerts
$success: #2ecc71;    // Fresh green for success messages
$warning: #f39c12;    // A warm yellow for warnings
$primary: #3498db;    // Vibrant blue for primary actions
$info: #8e44ad;       // A bold purple for informational messages
$inverse: #34495e;    // A deep navy for inverse elements
$muted: #95a5a6;      // Muted gray for less emphasis
$dark: #2c3e50;       // Dark gray for footer and header
$light: #ecf0f1;      // Light gray for backgrounds
$extra-light: #bdc3c7; // Very light gray for subtle elements
$secondary: #7f8c8d;  // Secondary muted color
$facebook: #3b5998;   // Facebook brand color
$twitter: #1da1f2;    // Twitter brand color

/* Light Colors*/
$light-danger: #f2b2b6; //  light red for danger
$light-success: #c1e0c1; //  light green for success
$light-warning: #f1d29b; //  light yellow for warnings
$light-primary: #d1e1ef; //  light blue for primary
$light-info: #b3e0e5;    //  light cyan for info
$light-inverse: #e0e0e0; //  light gray for inverse
$light-megna: #d1f1ef;   //  light teal for extra light


$danger-dark: #c0392b;   // Dark red for danger
$success-dark: #27ae60;  // Dark green for success
$warning-dark: #e67e22;  // Dark orange for warning
$primary-dark: #2980b9;  // Dark blue for primary
$info-dark: #6f2c91;     // Dark purple for info
$red-dark: #c0392b;      // Darker red
$inverse-dark: #2c3e50;  // Dark navy for inverse
$dark-transparent: rgba(0, 0, 0, 0.1); // Slightly transparent black

/*Normal Color*/
$white: #ffffff;         // White for backgrounds
$red: #e74c3c;           // Bright red
$yellow: #f1c40f;        // Bright yellow
$purple: #9b59b6;        // Vibrant purple
$blue: #3498db;          // Bright blue
$megna: #1abc9c;         // Teal color
$orange: #e67e22;        // Bright orange

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.2); // Slightly darker border
$radius: 6px; // Slightly larger radius for a modern touch
$card-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); // Softer card shadow
$img-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); // Softer image shadow

/*Gradiant*/

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

$banner-image: url(../images/landingpage/banner-bg.jpg);
$c2a-image: url(../images/landingpage/comingsoon.jpg);
$c2aservices-image: url(../images/landingpage/security.jpg);
$banner-slider: url(../../images/static-slider/slider10/img1.jpg);
$form-banner: url(../../images/form-banners/banner1/banner-bg.jpg);
$form-banner2: url(../../images/form-banners/banner1/banner-bg2.png);
$policies-image: url(../images/landingpage/policiesbackgrouund.png);
$c2a-image: url(../images/landingpage/comingsoon.jpg);
$c2a-image: url(../images/landingpage/comingsoon.jpg);
$datarecovery-image: url(../images/landingpage/Data-recovery.png);
$pcmaintainance-image: url(../images/landingpage/pc-maintainence.png);
$junkremoval-image: url(../images/landingpage/junk-removal.png);
$remotesupport-image: url(../images/landingpage/remote-support.png);
$ask-masteros-image: url(../images/landingpage/security5.jpg);
$file-image: url(../images/services/downloads-image/file.png);
$contact-us: url(../images/info/Contact-us.jpg);
$satisfaction-image: url(../images/info/Satisfaction.jpg);
$about-us-image: url(../images/info/About-us.jpg);
$about-us-commitment: url(../images/info/Commitment-to-Excellence.jpg);
$Partnership-image: url(../images/features/feature30/Partnership.png)

// $t1: url(../../images/team/t1.jpg);
// $t2: url(../../images/team/t2.jpg);
// $t3: url(../../images/team/t3.jpg);
// $t4: url(../../images/team/t4.jpg);