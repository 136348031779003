.ask-masteros-services {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: hidden; /* Change to hidden to prevent overflow */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $ask-masteros-image;
}

.ask-masteros-services::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: inherit;
    background-repeat: inherit;
    background-position: inherit;
    filter: blur(0px);
    z-index: 1;
}

.ask-masteros-services > * {
    position: relative;
    z-index: 2;
    color: white;
}

/* Media query for small devices */
@media (max-width: 600px) { /* Adjust max-width as needed */
    .ask-masteros-services::before {
        filter: blur(10px); /* Adjust the blur amount as needed */
    }
}

.remote-support-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $remotesupport-image;
}

.remote-support-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.remote-support-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.pc-maintenance-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $pcmaintainance-image;
}

.pc-maintenance-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.pc-maintenance-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.virus-malware-removal-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $junkremoval-image;
}

.virus-malware-removal-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.virus-malware-removal-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.datarecovery-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $datarecovery-image;
}

.datarecovery-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.datarecovery-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.antivirus-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $datarecovery-image;
}

.antivirus-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.antivirus-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.network-security-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $datarecovery-image;
}

.network-security-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.network-security-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.tech-support-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $datarecovery-image;
}

.tech-support-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.tech-support-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.antivirus-support-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $datarecovery-image;
}

.antivirus-support-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.antivirus-support-cover > * {
    position: relative;
    z-index: 2;
    color: white;
}

.download-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 400px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $themecolor;
}

.download-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    filter: blur(175px);
    z-index: 1;
}

.download-section > * {
    position: relative;
    z-index: 2;
    color: white;
}

.discription-download-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 550px;
    overflow: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: $file-image;
}
