.pricing-card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05);
    }
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;

    .icon {
        font-size: 30px;
        margin-right: 10px;
    }

    .emoji {
        font-size: 30px; 
        margin-right: 10px;
    }
}

.features-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    display: flex; 
    align-items: flex-start;
    margin: 5px 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feature-icon {
    margin-right: 8px;
}

.cross-icon {
    color: red;
    margin-right: 10px;
    font-size: 20px;
}

.check-icon {
    color: green;
    align-items: end;
    position: relative;
    font-size: 20px;
}

.more-info {
    color: #bbc1c1;

    .more-info-link {
        color: #007bff;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.price.red {
    color: red;
    text-decoration: line-through;
}

.price.strong {
    font-weight: bold;
    font-size: 2.5em;
    color: cadetblue;
}

.price-spacing {
    margin-left: 20px;
}

.title {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.list-checkmark {
    list-style-type:inherit;
    font-size: 0.9em;
    padding: 0;
}

.list-checkmark li {
    display: flex; /* Use flexbox for alignment */
    align-items:flex-end; /* Align items vertically */
    margin: 10px 0; /* Space between list items */
    text-align: left; /* Align text to the left */
}

.check-icon {
    color: green; /* Adjust color as needed */
    margin-right: 0px; /* Space between icon and text */
}

.eset-toggle-container {
    position: relative; /* Change to relative */
    z-index: 1000;
    display:flow-root;
    cursor: pointer;
}

.eset-tooltip-element {
    visibility: hidden;
    width: 150px; 
    background-color: #fff; 
    color: #000;
    text-align: left;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 20px;
    position: absolute;
    z-index: 100000;
    bottom: 125%; 
    left: 50%;
    transform: translateX(-50%);
    transition: visibility 0.2s, opacity 0.2s;
    opacity: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add a shadow */
}

.eset-toggle-container:hover .eset-tooltip-element {
    visibility: visible; 
    opacity: 1;
}
